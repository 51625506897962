"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.parseValidityDate = exports.parseCataloguesLandingPageData = exports.parseCatalogue = exports.getDigitalCatalogue = void 0;
var _net = require("../models/graphql-types/net");
const parseValidityDate = (param)=>{
    let { validFrom, validTo, formatValidityDate } = param;
    if (validFrom === undefined || validTo === undefined || new Date(validTo).getFullYear() > 3000) {
        return null;
    }
    return "".concat(formatValidityDate(new Date(validFrom)), " - ").concat(formatValidityDate(new Date(validTo)));
};
exports.parseValidityDate = parseValidityDate;
const parseCatalogue = (formatValidityDate)=>(catalogue)=>{
        const { validFrom, validTo, imageUrl, link, title } = catalogue !== null && catalogue !== void 0 ? catalogue : {};
        const catalogueLink = link === null || link === void 0 ? void 0 : link.relativeUrl;
        if (!catalogueLink) {
            return null;
        }
        return {
            imageUrl: imageUrl !== null && imageUrl !== void 0 ? imageUrl : null,
            link: catalogueLink,
            title: title !== null && title !== void 0 ? title : null,
            validity: parseValidityDate({
                validFrom,
                validTo,
                formatValidityDate
            })
        };
    };
exports.parseCatalogue = parseCatalogue;
const getDigitalCatalogue = (param)=>{
    let { catalogueType, data, formatValidityDate } = param;
    var _data_application_catalogueLandingPage_digitalCatalogues, _data_application_catalogueLandingPage, _data_application;
    const currentCatalogue = (_data_application = data.application) === null || _data_application === void 0 ? void 0 : (_data_application_catalogueLandingPage = _data_application.catalogueLandingPage) === null || _data_application_catalogueLandingPage === void 0 ? void 0 : (_data_application_catalogueLandingPage_digitalCatalogues = _data_application_catalogueLandingPage.digitalCatalogues) === null || _data_application_catalogueLandingPage_digitalCatalogues === void 0 ? void 0 : _data_application_catalogueLandingPage_digitalCatalogues.find((catalogue)=>(catalogue === null || catalogue === void 0 ? void 0 : catalogue.catalogueType) === catalogueType);
    return parseCatalogue(formatValidityDate)(currentCatalogue);
};
exports.getDigitalCatalogue = getDigitalCatalogue;
const parseCataloguesLandingPageData = (data, formatValidityDate)=>{
    var _data_application_catalogueLandingPage, _data_application;
    var _data_application_catalogueLandingPage_otherCatalogues;
    return {
        currentCatalogue: getDigitalCatalogue({
            catalogueType: _net.CatalogueType.DigitalCurrent,
            data,
            formatValidityDate
        }),
        nextCatalogue: getDigitalCatalogue({
            catalogueType: _net.CatalogueType.DigitalNext,
            data,
            formatValidityDate
        }),
        otherCatalogues: ((_data_application_catalogueLandingPage_otherCatalogues = (_data_application = data.application) === null || _data_application === void 0 ? void 0 : (_data_application_catalogueLandingPage = _data_application.catalogueLandingPage) === null || _data_application_catalogueLandingPage === void 0 ? void 0 : _data_application_catalogueLandingPage.otherCatalogues) !== null && _data_application_catalogueLandingPage_otherCatalogues !== void 0 ? _data_application_catalogueLandingPage_otherCatalogues : []).map(parseCatalogue(formatValidityDate)).filter((catalogue)=>catalogue !== null)
    };
};
exports.parseCataloguesLandingPageData = parseCataloguesLandingPageData;
