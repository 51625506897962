"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.useDisplay = exports.onMessage = void 0;
var _react = require("react");
var _utils = require("../utils");
const onMessage = (param)=>{
    let { data } = param;
    if (!data) {
        return;
    }
    const { trigger, url } = data;
    const productId = url ? new URL(url).searchParams.get('code') : undefined;
    if (productId && trigger === 'showProduct') {
        (0, _utils.addToBasket)({
            pageIndex: 0,
            productId,
            quantity: 1,
            title: '',
            originPage: 1,
            description: '',
            price: 0
        });
    }
};
exports.onMessage = onMessage;
const useDisplay = ()=>{
    (0, _react.useEffect)(()=>{
        window.addEventListener('message', onMessage, false);
    }, []);
};
exports.useDisplay = useDisplay;
