"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.useOverviewContext = void 0;
var _react = require("react");
var _contexts = require("../contexts");
const useOverviewContext = ()=>{
    const context = (0, _react.useContext)(_contexts.OverviewContext);
    if (context) {
        return context;
    }
    throw new Error('OverviewContext value must be set.');
};
exports.useOverviewContext = useOverviewContext;
