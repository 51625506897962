import { Logger } from '@ori/logger';
import { PACKAGE_NAME, TEAM_NAME } from './constants-generated';
export const mainLogger = new Logger(PACKAGE_NAME, {
    teamName: TEAM_NAME
});
export const mypagesMainLogger = new Logger(PACKAGE_NAME, {
    part: 'mypages',
    teamName: TEAM_NAME
});
export const catalogueMainLogger = new Logger(PACKAGE_NAME, {
    part: 'catalogues',
    teamName: TEAM_NAME
});
export const personalProductListMainLogger = new Logger(PACKAGE_NAME, {
    part: 'personal-products-list',
    teamName: TEAM_NAME
});
export const ingredientDetailMainLogger = new Logger(PACKAGE_NAME, {
    part: 'ingredient-detail',
    teamName: TEAM_NAME
});
export const productListingMainLogger = new Logger(PACKAGE_NAME, {
    part: 'product-listing',
    teamName: TEAM_NAME
});
export const productDetailMainLogger = new Logger(PACKAGE_NAME, {
    part: 'product-detail',
    teamName: TEAM_NAME
});
export const brandsMainLogger = new Logger(PACKAGE_NAME, {
    part: 'brands',
    teamName: TEAM_NAME
});
export const landingMainLogger = new Logger(PACKAGE_NAME, {
    part: 'landing',
    teamName: TEAM_NAME
});
export const catalogueIPaperMainLogger = new Logger(PACKAGE_NAME, {
    part: 'catalogue-ipaper',
    teamName: TEAM_NAME
});
export const cataloguesLandingPageMainLogger = new Logger(PACKAGE_NAME, {
    part: 'catalogues-landing',
    teamName: TEAM_NAME
});
export const FavoritesListingMainLogger = new Logger(PACKAGE_NAME, {
    part: 'favorites-listing',
    teamName: TEAM_NAME
});
export const olapicPageMainLogger = new Logger(PACKAGE_NAME, {
    part: 'olapic',
    teamName: TEAM_NAME
});
export const myPagesPersonalProductsListsMainLogger = new Logger(PACKAGE_NAME, {
    part: 'mypages-personal-products-lists',
    teamName: TEAM_NAME
});
export const myPagesProductListMainLogger = new Logger(PACKAGE_NAME, {
    part: 'mypages-product-list',
    teamName: TEAM_NAME
});
export const newMyPagesPersonalProductsListsMainLogger = new Logger(PACKAGE_NAME, {
    part: 'new-mypages-personal-products-lists',
    teamName: TEAM_NAME
});
export const rewardsMainLogger = new Logger(PACKAGE_NAME, {
    part: 'rewards',
    teamName: TEAM_NAME
});
export const myPagesRewardsMainLogger = new Logger(PACKAGE_NAME, {
    part: 'mypages-rewards',
    teamName: TEAM_NAME
});
export const ingredientLibraryMainLogger = new Logger(PACKAGE_NAME, {
    part: 'ingredient-library',
    teamName: TEAM_NAME
});
export const searchResultsMainLogger = new Logger(PACKAGE_NAME, {
    part: 'search-results',
    teamName: TEAM_NAME
});
