"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.onClickEvent = void 0;
var _addToBasket = _interopRequireWildcard(require("@ori-events/add-to-basket"));
var AddToBasketEvent = _addToBasket;
var OpenProductInfoDialog = _interopRequireWildcard(require("@ori-events/open-product-info-dialog"));
var OpenShadePicker = _interopRequireWildcard(require("@ori-events/open-shade-picker"));
var _analyticsData = require("@ori/analytics-data");
var _productBox = require("@ori/product-box");
function _getRequireWildcardCache(e) {
    if ("function" != typeof WeakMap) return null;
    var r = new WeakMap(), t = new WeakMap();
    return (_getRequireWildcardCache = function(e) {
        return e ? t : r;
    })(e);
}
function _interopRequireWildcard(e, r) {
    if (!r && e && e.__esModule) return e;
    if (null === e || "object" != typeof e && "function" != typeof e) return {
        default: e
    };
    var t = _getRequireWildcardCache(r);
    if (t && t.has(e)) return t.get(e);
    var n = {
        __proto__: null
    }, a = Object.defineProperty && Object.getOwnPropertyDescriptor;
    for(var u in e)if ("default" !== u && ({}).hasOwnProperty.call(e, u)) {
        var i = a ? Object.getOwnPropertyDescriptor(e, u) : null;
        i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u];
    }
    return n.default = e, t && t.set(e, n), n;
}
const onClickEvent = (param)=>{
    let { pageIndex, product } = param;
    var _product_concept;
    if ((product === null || product === void 0 ? void 0 : product.productCode) == null) {
        return;
    }
    const hasAutoSubscription = (0, _productBox.mapHasAutoSubscription)(product.labels);
    const isMultiShade = ((_product_concept = product.concept) === null || _product_concept === void 0 ? void 0 : _product_concept.products) && product.concept.products.length > 1;
    if (hasAutoSubscription) {
        window.dispatchEvent(new CustomEvent(OpenProductInfoDialog.Name, {
            detail: {
                mode: OpenProductInfoDialog.DialogMode.StandardBuy,
                placement: _analyticsData.Placement.DigitalCatalogueECatalogue,
                productCodes: [
                    product.productCode
                ]
            }
        }));
    } else if (isMultiShade) {
        window.dispatchEvent(new CustomEvent(OpenShadePicker.Name, {
            detail: {
                placement: _analyticsData.Placement.DigitalCatalogueECatalogue,
                productCode: product.productCode
            }
        }));
    } else {
        var _product_formattedPrice_price, _product_formattedPrice, _product_concept1;
        var _product_formattedPrice_price_currentPrice, _product_concept_name;
        window.dispatchEvent(new CustomEvent(AddToBasketEvent.Name, {
            detail: {
                formattedPrice: (_product_formattedPrice_price_currentPrice = (_product_formattedPrice = product.formattedPrice) === null || _product_formattedPrice === void 0 ? void 0 : (_product_formattedPrice_price = _product_formattedPrice.price) === null || _product_formattedPrice_price === void 0 ? void 0 : _product_formattedPrice_price.currentPrice) !== null && _product_formattedPrice_price_currentPrice !== void 0 ? _product_formattedPrice_price_currentPrice : '',
                itemCode: product.productCode,
                localName: (_product_concept_name = (_product_concept1 = product.concept) === null || _product_concept1 === void 0 ? void 0 : _product_concept1.name) !== null && _product_concept_name !== void 0 ? _product_concept_name : '',
                method: _addToBasket.Method.BUY_FOR_MONEY,
                placement: _analyticsData.Placement.DigitalCatalogueECatalogue,
                position: pageIndex + 1,
                quantity: 1
            }
        }));
    }
};
exports.onClickEvent = onClickEvent;
