"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.getApiInstance = void 0;
var _constants = require("./constants");
/* istanbul ignore file */ const getApiInstance = ()=>{
    const iPaperFrame = document.getElementById(_constants.IPAPER_IFRAME_ID);
    return window.iPaperJsApi ? window.iPaperJsApi(iPaperFrame, 3) : undefined;
};
exports.getApiInstance = getApiInstance;
