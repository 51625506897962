"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.getCatalogueCodeByType = void 0;
var _staticApiClientReact = require("@ori/static-api-client-react");
const getCatalogueCodeByType = async (param)=>{
    let { catalogueType, language, staticApiClient, tenant } = param;
    var _data_catalogues_find_metadataCollection_, _data_catalogues_find;
    const { data } = await (0, _staticApiClientReact.getTenantApplicationsCatalogueOverview)(staticApiClient, tenant, {
        lang: language
    });
    var _data_catalogues_find_metadataCollection__id;
    return (_data_catalogues_find_metadataCollection__id = (_data_catalogues_find = data.catalogues.find((catalogue)=>catalogue.catalogueType === catalogueType)) === null || _data_catalogues_find === void 0 ? void 0 : (_data_catalogues_find_metadataCollection_ = _data_catalogues_find.metadataCollection[0]) === null || _data_catalogues_find_metadataCollection_ === void 0 ? void 0 : _data_catalogues_find_metadataCollection_.id) !== null && _data_catalogues_find_metadataCollection__id !== void 0 ? _data_catalogues_find_metadataCollection__id : null;
};
exports.getCatalogueCodeByType = getCatalogueCodeByType;
