"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.useToggle = void 0;
var _react = require("react");
const useToggle = function() {
    let initialState = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
    const [state, setState] = (0, _react.useState)(initialState);
    const toggleState = (0, _react.useCallback)(()=>setState((prevState)=>!prevState), []);
    return [
        state,
        toggleState
    ];
};
exports.useToggle = useToggle;
