"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
var _defaultTranslations = require("./defaultTranslations");
Object.keys(_defaultTranslations).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _defaultTranslations[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _defaultTranslations[key];
        }
    });
});
var _swrConfig = require("./swrConfig");
Object.keys(_swrConfig).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _swrConfig[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _swrConfig[key];
        }
    });
});
var _variables = require("./variables");
Object.keys(_variables).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _variables[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _variables[key];
        }
    });
});
