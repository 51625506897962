"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.usePageNumberContext = void 0;
var _react = require("react");
var _contexts = require("../contexts");
const usePageNumberContext = ()=>{
    const context = (0, _react.useContext)(_contexts.PageNumberContext);
    if (context) {
        return context;
    }
    throw new Error('PageNumberContext value must be set.');
};
exports.usePageNumberContext = usePageNumberContext;
