"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.PageNumber = exports.Item = exports.ImageWrapper = exports.Button = void 0;
var _mui = require("@ori-ui/mui");
var _constants = require("../../constants");
const Item = exports.Item = (0, _mui.styled)('li')((param)=>{
    let { theme } = param;
    return {
        '&:first-of-type': {
            gridColumn: '1 / -1',
            [theme.breakpoints.up('xs')]: {
                gridColumn: 'auto'
            }
        }
    };
});
const Button = exports.Button = (0, _mui.styled)(_mui.ButtonBase)((param)=>{
    let { theme } = param;
    return {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        overflow: 'hidden',
        transition: 'filter 0.2s',
        '&:hover, &:focus': {
            filter: 'brightness(0.8)'
        }
    };
});
const PageNumber = exports.PageNumber = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        display: 'block',
        width: '38px',
        height: '23px',
        background: theme.palette.grey[100],
        borderRadius: '0 0 5px 5px'
    };
});
const ImageWrapper = exports.ImageWrapper = (0, _mui.styled)('div')(()=>({
        borderRadius: '5px',
        height: _constants.OVERVIEW_IMAGE_HEIGHT,
        overflow: 'hidden'
    }));
