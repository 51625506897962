"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.loadIPaperAPIScript = void 0;
var _constants = require("./constants");
/* istanbul ignore file */ /**
 * Function that returns existing ipaper-script in <head> or creates a new one in <head> and returns it.
 * @returns script element or null
 */ const loadIPaperAPIScript = ()=>{
    const existingScript = document.getElementById(_constants.IPAPER_SCRIPT_ID);
    // ipaper-script already exists in <head>, we can return it.
    if (existingScript) {
        return existingScript;
    }
    // ipaper-script does not exist in <head>, we need to create it and append it to <head>.
    const script = document.createElement('script');
    const head = document.querySelector('head');
    // <head> does not exist, we end here.
    if (!head) {
        return null;
    }
    // <head> exists, script is added and values are assigned to it.
    head.append(script);
    script.id = _constants.IPAPER_SCRIPT_ID;
    script.async = true;
    script.src = _constants.IPAPER_SCRIPT_URL;
    return script;
};
exports.loadIPaperAPIScript = loadIPaperAPIScript;
