"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Root = exports.ActionsContainer = void 0;
var _mui = require("@ori-ui/mui");
var _constants = require("../../constants");
const Root = exports.Root = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        alignItems: 'center',
        background: theme.palette.common.white,
        boxSizing: 'border-box',
        color: theme.palette.grey[900],
        display: 'flex',
        fontSize: '2.6rem',
        height: _constants.TOOLBAR_HEIGHT,
        justifyContent: 'space-between',
        maxWidth: '100%',
        width: '100%',
        padding: theme.spacing(0, 2),
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(0, 5)
        }
    };
});
const ActionsContainer = exports.ActionsContainer = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        alignItems: 'center',
        display: 'flex',
        flex: 1,
        gap: theme.spacing(3),
        justifyContent: 'center',
        '&:first-of-type': {
            justifyContent: 'flex-start'
        },
        '&:last-child': {
            justifyContent: 'flex-end'
        },
        '&:only-child': {
            justifyContent: 'space-between'
        }
    };
});
