"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
var _OverviewContent = require("./OverviewContent");
Object.keys(_OverviewContent).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _OverviewContent[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _OverviewContent[key];
        }
    });
});
