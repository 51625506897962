"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Root = exports.Inner = void 0;
var _mui = require("@ori-ui/mui");
const Root = exports.Root = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(4),
        [theme.breakpoints.up('sm')]: {
            gap: theme.spacing(6)
        }
    };
});
const Inner = exports.Inner = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        maxWidth: '100%',
        [theme.breakpoints.up('sm')]: {
            gap: theme.spacing(3),
            maxWidth: 400
        }
    };
});
