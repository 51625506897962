"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
var _ApiProvider = require("./ApiProvider");
Object.keys(_ApiProvider).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _ApiProvider[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _ApiProvider[key];
        }
    });
});
var _CatalogueIPaperContainer = require("./CatalogueIPaperContainer");
Object.keys(_CatalogueIPaperContainer).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _CatalogueIPaperContainer[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _CatalogueIPaperContainer[key];
        }
    });
});
var _CataloguesLandingPageContainer = require("./CataloguesLandingPageContainer");
Object.keys(_CataloguesLandingPageContainer).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _CataloguesLandingPageContainer[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _CataloguesLandingPageContainer[key];
        }
    });
});
var _IPaperWrapper = require("./IPaperWrapper");
Object.keys(_IPaperWrapper).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _IPaperWrapper[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _IPaperWrapper[key];
        }
    });
});
