"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
var _addToBasket = require("./addToBasket");
Object.keys(_addToBasket).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _addToBasket[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _addToBasket[key];
        }
    });
});
var _generateImageUrls = require("./generateImageUrls");
Object.keys(_generateImageUrls).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _generateImageUrls[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _generateImageUrls[key];
        }
    });
});
var _getCatalogueMetadata = require("./getCatalogueMetadata");
Object.keys(_getCatalogueMetadata).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _getCatalogueMetadata[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _getCatalogueMetadata[key];
        }
    });
});
var _getCatalogueMetadataForProviderType = require("./getCatalogueMetadataForProviderType");
Object.keys(_getCatalogueMetadataForProviderType).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _getCatalogueMetadataForProviderType[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _getCatalogueMetadataForProviderType[key];
        }
    });
});
var _getCatalogueUrlAndId = require("./getCatalogueUrlAndId");
Object.keys(_getCatalogueUrlAndId).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _getCatalogueUrlAndId[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _getCatalogueUrlAndId[key];
        }
    });
});
var _getCorrectCatalogueIdFormat = require("./getCorrectCatalogueIdFormat");
Object.keys(_getCorrectCatalogueIdFormat).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _getCorrectCatalogueIdFormat[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _getCorrectCatalogueIdFormat[key];
        }
    });
});
var _isDefined = require("./isDefined");
Object.keys(_isDefined).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _isDefined[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _isDefined[key];
        }
    });
});
var _loadIPaperAPIScript = require("./loadIPaperAPIScript");
Object.keys(_loadIPaperAPIScript).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _loadIPaperAPIScript[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _loadIPaperAPIScript[key];
        }
    });
});
var _parseCataloguesLandingPageData = require("./parseCataloguesLandingPageData");
Object.keys(_parseCataloguesLandingPageData).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _parseCataloguesLandingPageData[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _parseCataloguesLandingPageData[key];
        }
    });
});
var _sendProductClickAnalytics = require("./sendProductClickAnalytics");
Object.keys(_sendProductClickAnalytics).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _sendProductClickAnalytics[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _sendProductClickAnalytics[key];
        }
    });
});
var _setCssVariables = require("./setCssVariables");
Object.keys(_setCssVariables).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _setCssVariables[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _setCssVariables[key];
        }
    });
});
var _constants = require("./constants");
Object.keys(_constants).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _constants[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _constants[key];
        }
    });
});
var _getPageNumber = require("./getPageNumber");
Object.keys(_getPageNumber).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _getPageNumber[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _getPageNumber[key];
        }
    });
});
