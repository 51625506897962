"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.getProduct = void 0;
var _fetching = require("@ori/fetching");
var _queriesNet = require("../queries-net");
const getProduct = async (param)=>{
    let { graphQlUrl, headers, productCode, token } = param;
    if (!token) {
        return {
            product: null
        };
    }
    const url = new URL(graphQlUrl);
    url.pathname = '/graphql';
    const data = await (0, _fetching.fetchGraphql)({
        query: _queriesNet.productQuery,
        url: url.toString(),
        headers,
        variables: {
            productCode
        }
    });
    return data;
};
exports.getProduct = getProduct;
