"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
var _cataloguesLandingPage = require("./cataloguesLandingPage");
Object.keys(_cataloguesLandingPage).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _cataloguesLandingPage[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _cataloguesLandingPage[key];
        }
    });
});
var _productQuery = require("./productQuery");
Object.keys(_productQuery).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _productQuery[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _productQuery[key];
        }
    });
});
