"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.useGetCatalogueLandingPageData = void 0;
var _configurationManager = require("@ori/configuration-manager");
var _culture = require("@ori/culture");
var _fetching = require("@ori/fetching");
var _logger = require("@ori/logger");
var _presentationHttp = require("@ori/presentation-http");
var _react = require("react");
var _queriesNet = require("../queries-net");
var _utils = require("../utils");
// istanbul ignore file -- api
const useGetCatalogueLandingPageData = ()=>{
    const { graphQlNetUrl } = (0, _configurationManager.useServiceUrls)([
        'graphQlNet'
    ]);
    const { headers, loading: loadingHeaders } = (0, _presentationHttp.useHeaders)();
    const childLogger = (0, _logger.useLogger)('useGetCatalogueLandingPageData');
    const { culture, timeZone } = (0, _culture.useCulture)();
    const formatValidityDate = (0, _react.useCallback)((date)=>(0, _culture.formatDate)(date, _culture.DateFormat.ShortDate, culture, timeZone), [
        culture,
        timeZone
    ]);
    const onError = (0, _react.useCallback)((error)=>{
        childLogger.error(new Error('Failed fetch of catalogue landing page data', {
            cause: error
        }));
    }, [
        childLogger
    ]);
    const { data, error } = (0, _fetching.useGraphqlQuery)({
        query: _queriesNet.cataloguesLandingPageQuery,
        url: graphQlNetUrl,
        headers,
        onError,
        skip: loadingHeaders,
        swrConfig: {
            revalidateIfStale: false,
            revalidateOnFocus: false,
            revalidateOnReconnect: false
        }
    });
    return (0, _react.useMemo)(()=>({
            data: data ? (0, _utils.parseCataloguesLandingPageData)(data, formatValidityDate) : null,
            loading: data === undefined && error === undefined || loadingHeaders
        }), [
        data,
        error,
        formatValidityDate,
        loadingHeaders
    ]);
};
exports.useGetCatalogueLandingPageData = useGetCatalogueLandingPageData;
