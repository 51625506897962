"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.useGetCatalogueStructure = void 0;
var _environment = require("@ori/environment");
var _i18n = require("@ori/i18n");
var _logger = require("@ori/logger");
var _staticApiClientReact = require("@ori/static-api-client-react");
var _utils = require("../utils");
var _useCatalogueUrlAndId = require("./useCatalogueUrlAndId");
// istanbul ignore next -- No need to test usage of logger.
const onError = (localLogger)=>(error)=>{
        localLogger.error(new Error('Failed fetch of catalogue structure', {
            cause: error
        }));
    };
const useGetCatalogueStructure = ()=>{
    const { language } = (0, _i18n.useTranslationsWithNamespaces)();
    const { tenant } = (0, _environment.useEnvironment)();
    const localLogger = (0, _logger.useLogger)('useGetCatalogueStructure');
    const { catalogueId } = (0, _useCatalogueUrlAndId.useCatalogueUrlAndId)();
    const eCatalogueId = (0, _utils.getCorrectCatalogueIdFormat)(catalogueId);
    const { data, isValidating } = (0, _staticApiClientReact.useGetTenantECatalogueStructure)(tenant, eCatalogueId, {
        lang: language
    }, {
        swr: {
            enabled: Boolean(eCatalogueId),
            revalidateIfStale: false,
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            onError: onError(localLogger)
        }
    });
    var _data_pages_length;
    const totalPageCount = (_data_pages_length = data === null || data === void 0 ? void 0 : data.pages.length) !== null && _data_pages_length !== void 0 ? _data_pages_length : 0;
    return {
        totalPageCount,
        isLoadingTotalPageCount: isValidating
    };
};
exports.useGetCatalogueStructure = useGetCatalogueStructure;
