"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
var _cataloguesLandingPageContext = require("./cataloguesLandingPageContext");
Object.keys(_cataloguesLandingPageContext).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _cataloguesLandingPageContext[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _cataloguesLandingPageContext[key];
        }
    });
});
var _configurationContext = require("./configurationContext");
Object.keys(_configurationContext).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _configurationContext[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _configurationContext[key];
        }
    });
});
var _overviewContext = require("./overviewContext");
Object.keys(_overviewContext).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _overviewContext[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _overviewContext[key];
        }
    });
});
var _pageNumberContext = require("./pageNumberContext");
Object.keys(_pageNumberContext).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _pageNumberContext[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _pageNumberContext[key];
        }
    });
});
