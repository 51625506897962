"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.transformPageInput = void 0;
var _constants = require("../../constants");
const transformPageInput = function(input) {
    let maxPage = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : _constants.CATALOGUE_MAX_PAGE;
    const page = typeof input === 'string' ? Number.parseInt(input, 10) : Math.trunc(input);
    if (Number.isNaN(page) || page < _constants.CATALOGUE_DEFAULT_PAGE) {
        return _constants.CATALOGUE_DEFAULT_PAGE;
    }
    if (page > maxPage) {
        return maxPage;
    }
    return page;
};
exports.transformPageInput = transformPageInput;
