"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.useDownloadButtonData = void 0;
var _hooks = require("../../hooks");
var _useCatalogueUrlAndId = require("../../hooks/useCatalogueUrlAndId");
var _constants = require("./constants");
const useDownloadButtonData = ()=>{
    const { catalogueUrl } = (0, _useCatalogueUrlAndId.useCatalogueUrlAndId)();
    const { catalogueCode } = (0, _hooks.useConfiguration)();
    return {
        downloadLink: catalogueUrl ? new URL(_constants.DOWNLOAD_LINK_PATH_LAST_ITEM, catalogueUrl.replace(/\/?$/, '/')).toString() : '',
        catalogueId: catalogueCode
    };
};
exports.useDownloadButtonData = useDownloadButtonData;
