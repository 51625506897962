"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Root = exports.MobileLatestCataloguesDescription = exports.Inner = exports.DesktopLatestCataloguesDescription = void 0;
var _mui = require("@ori-ui/mui");
var _presentationComponents = require("@ori/presentation-components");
var _presentationUtils = require("@ori/presentation-utils");
var _LatestCataloguesDescription = require("../LatestCataloguesDescription");
const Root = exports.Root = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(4)
    };
});
const Inner = exports.Inner = (0, _mui.styled)('div', {
    shouldForwardProp: (0, _presentationUtils.shouldForwardProp)()
})((param)=>{
    let { showNextCatalogue, theme } = param;
    return {
        display: 'grid',
        gridTemplateColumns: "minmax(0, ".concat(_presentationComponents.CATEGORY_BOX_MOBILE_LARGE_WIDTH, "px) minmax(0, ").concat(_presentationComponents.CATEGORY_BOX_MOBILE_LARGE_WIDTH, "px)"),
        gap: theme.spacing(2),
        justifyContent: 'center',
        [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: "".concat(showNextCatalogue ? "minmax(0, ".concat(_presentationComponents.CATEGORY_BOX_DESKTOP_LARGE_WIDTH, "px)") : '', " minmax(0, ").concat(_presentationComponents.CATEGORY_BOX_DESKTOP_LARGE_WIDTH, "px) minmax(0, 400px)"),
            gap: theme.spacing(8)
        }
    };
});
const MobileLatestCataloguesDescription = exports.MobileLatestCataloguesDescription = (0, _mui.styled)(_LatestCataloguesDescription.LatestCataloguesDescription)((param)=>{
    let { theme } = param;
    return {
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    };
});
const DesktopLatestCataloguesDescription = exports.DesktopLatestCataloguesDescription = (0, _mui.styled)(_LatestCataloguesDescription.LatestCataloguesDescription)((param)=>{
    let { theme } = param;
    return {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'flex'
        }
    };
});
