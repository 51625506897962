"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
var _CataloguesLandingPage = require("./CataloguesLandingPage");
Object.keys(_CataloguesLandingPage).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _CataloguesLandingPage[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _CataloguesLandingPage[key];
        }
    });
});
var _DownloadButton = require("./DownloadButton");
Object.keys(_DownloadButton).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _DownloadButton[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _DownloadButton[key];
        }
    });
});
var _ImageWithSkeleton = require("./ImageWithSkeleton");
Object.keys(_ImageWithSkeleton).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _ImageWithSkeleton[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _ImageWithSkeleton[key];
        }
    });
});
var _IPaper = require("./IPaper");
Object.keys(_IPaper).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _IPaper[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _IPaper[key];
        }
    });
});
var _Loading = require("./Loading");
Object.keys(_Loading).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _Loading[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _Loading[key];
        }
    });
});
var _PageCounter = require("./PageCounter");
Object.keys(_PageCounter).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _PageCounter[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _PageCounter[key];
        }
    });
});
var _PageNavigator = require("./PageNavigator");
Object.keys(_PageNavigator).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _PageNavigator[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _PageNavigator[key];
        }
    });
});
var _Share = require("./Share");
Object.keys(_Share).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _Share[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _Share[key];
        }
    });
});
var _Toolbar = require("./Toolbar");
Object.keys(_Toolbar).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _Toolbar[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _Toolbar[key];
        }
    });
});
