"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.useTranslations = void 0;
var _i18n = require("@ori/i18n");
/* istanbul ignore file */ /**
 * DO NOT EDIT!
 * This file was autogenerated by npm script `localizations-codegen`.
 * Changes may cause incorrect behavior and will be lost when the file is regenerated.
 *
 * Run `npx localizations-codegen` to regenerate.
 */ /**
 * A hook for accessing translations within a specific namespace along with a formatting function.
 *
 * @returns The translations within the specified namespace and a formatting function.
 */ const useTranslations = ()=>(0, _i18n.useTranslationsFromNamespace)();
exports.useTranslations = useTranslations;
