"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.getCatalogueIPaperData = void 0;
var _staticApiClientReact = require("@ori/static-api-client-react");
var _getCatalogueCodeByType = require("./getCatalogueCodeByType");
const getCatalogueIPaperData = async (param)=>{
    let { catalogueType, language, staticApiUrl, tenant } = param;
    const staticApiClient = new _staticApiClientReact.ApiClient({
        baseUrl: staticApiUrl,
        language,
        tenant
    });
    const [{ data }, catalogueCode] = await Promise.all([
        (0, _staticApiClientReact.getTenantApplicationsCatalogueIPaperConfiguration)(staticApiClient, tenant, {
            lang: language
        }),
        catalogueType === 'DigitalCurrent' || catalogueType === 'DigitalNext' ? (0, _getCatalogueCodeByType.getCatalogueCodeByType)({
            catalogueType,
            language,
            staticApiClient,
            tenant
        }) : Promise.resolve(null)
    ]);
    return {
        catalogueCode,
        config: data
    };
};
exports.getCatalogueIPaperData = getCatalogueIPaperData;
