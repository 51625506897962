"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
var _ShareButton = require("./ShareButton");
Object.keys(_ShareButton).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _ShareButton[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _ShareButton[key];
        }
    });
});
