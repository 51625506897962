"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.getPageNumber = void 0;
var _utils = require("../../utils");
const getPageNumber = (param)=>{
    let [leftPage, rightPage] = param;
    return (0, _utils.isDefined)(rightPage) ? "".concat(leftPage, " - ").concat(rightPage) : leftPage;
};
exports.getPageNumber = getPageNumber;
