"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
var _getCatalogueIPaperData = require("./getCatalogueIPaperData");
Object.keys(_getCatalogueIPaperData).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _getCatalogueIPaperData[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _getCatalogueIPaperData[key];
        }
    });
});
var _getProduct = require("./getProduct");
Object.keys(_getProduct).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _getProduct[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _getProduct[key];
        }
    });
});
var _types = require("./types");
Object.keys(_types).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _types[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _types[key];
        }
    });
});
var _useGetCatalogueLandingPageData = require("./useGetCatalogueLandingPageData");
Object.keys(_useGetCatalogueLandingPageData).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _useGetCatalogueLandingPageData[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _useGetCatalogueLandingPageData[key];
        }
    });
});
