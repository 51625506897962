"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
var _PageCounter = require("./PageCounter");
Object.keys(_PageCounter).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _PageCounter[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _PageCounter[key];
        }
    });
});
var _constants = require("./constants");
Object.keys(_constants).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _constants[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _constants[key];
        }
    });
});
