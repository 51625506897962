"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
const _tagged_template_literal = require("@swc/helpers/_/_tagged_template_literal");
function _templateObject() {
    const data = _tagged_template_literal._([
        "\n  query GetProduct($productCode: ID!) {\n    product(productCode: $productCode) {\n      concept {\n        brand {\n          name\n        }\n        imageUrl\n        name\n        products {\n          productCode\n        }\n      }\n      formattedPrice {\n        price {\n          currentPrice\n        }\n      }\n      labels {\n        labelKey\n        productLabelDomain\n        title\n      }\n      productCode\n      formattedPrice {\n        price {\n          currentPrice\n        }\n      }\n    }\n  }\n"
    ]);
    _templateObject = function() {
        return data;
    };
    return data;
}
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.productQuery = void 0;
var _fetching = require("@ori/fetching");
const productQuery = exports.productQuery = (0, _fetching.gql)(_templateObject());
