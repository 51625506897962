"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.getPageNumberParamFromUrl = void 0;
var _variables = require("../../constants/variables");
var _transformPageInput = require("./transformPageInput");
const getPageNumberParamFromUrl = ()=>{
    const pageNumberParam = new URLSearchParams(window.location.search).get(_variables.PAGE_NUMBER_QUERY_KEY);
    if (!pageNumberParam) {
        return _variables.CATALOGUE_DEFAULT_PAGE;
    }
    return (0, _transformPageInput.transformPageInput)(pageNumberParam);
};
exports.getPageNumberParamFromUrl = getPageNumberParamFromUrl;
