"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.sendProductClickAnalytics = void 0;
var ProductClickEvent = _interopRequireWildcard(require("@ori-events/product-click"));
var _analyticsData = require("@ori/analytics-data");
function _getRequireWildcardCache(e) {
    if ("function" != typeof WeakMap) return null;
    var r = new WeakMap(), t = new WeakMap();
    return (_getRequireWildcardCache = function(e) {
        return e ? t : r;
    })(e);
}
function _interopRequireWildcard(e, r) {
    if (!r && e && e.__esModule) return e;
    if (null === e || "object" != typeof e && "function" != typeof e) return {
        default: e
    };
    var t = _getRequireWildcardCache(r);
    if (t && t.has(e)) return t.get(e);
    var n = {
        __proto__: null
    }, a = Object.defineProperty && Object.getOwnPropertyDescriptor;
    for(var u in e)if ("default" !== u && ({}).hasOwnProperty.call(e, u)) {
        var i = a ? Object.getOwnPropertyDescriptor(e, u) : null;
        i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u];
    }
    return n.default = e, t && t.set(e, n), n;
}
const sendProductClickAnalytics = (product, pageIndex)=>{
    var _product_formattedPrice_price, _product_formattedPrice, _product_concept_brand, _product_concept, _product_concept1, _product_concept2;
    var _product_formattedPrice_price_currentPrice, _product_productCode, _product_concept_brand_name, _product_concept_name;
    const event = new CustomEvent(ProductClickEvent.Name, {
        detail: {
            placement: _analyticsData.Placement.DigitalCatalogueECatalogue,
            position: pageIndex + 1,
            products: [
                {
                    formattedPrice: (_product_formattedPrice_price_currentPrice = (_product_formattedPrice = product.formattedPrice) === null || _product_formattedPrice === void 0 ? void 0 : (_product_formattedPrice_price = _product_formattedPrice.price) === null || _product_formattedPrice_price === void 0 ? void 0 : _product_formattedPrice_price.currentPrice) !== null && _product_formattedPrice_price_currentPrice !== void 0 ? _product_formattedPrice_price_currentPrice : '',
                    globalBrandName: '',
                    globalCategoryName: '',
                    globalName: '',
                    itemCode: (_product_productCode = product.productCode) !== null && _product_productCode !== void 0 ? _product_productCode : '',
                    localBrandName: (_product_concept_brand_name = (_product_concept = product.concept) === null || _product_concept === void 0 ? void 0 : (_product_concept_brand = _product_concept.brand) === null || _product_concept_brand === void 0 ? void 0 : _product_concept_brand.name) !== null && _product_concept_brand_name !== void 0 ? _product_concept_brand_name : '',
                    localCategoryName: '',
                    localName: (_product_concept_name = (_product_concept1 = product.concept) === null || _product_concept1 === void 0 ? void 0 : _product_concept1.name) !== null && _product_concept_name !== void 0 ? _product_concept_name : '',
                    media: ((_product_concept2 = product.concept) === null || _product_concept2 === void 0 ? void 0 : _product_concept2.imageUrl) ? [
                        product.concept.imageUrl
                    ] : []
                }
            ]
        }
    });
    window.dispatchEvent(event);
};
exports.sendProductClickAnalytics = sendProductClickAnalytics;
