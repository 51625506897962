"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.TOOLBAR_HEIGHT = exports.PAGE_NUMBER_QUERY_KEY = exports.OVERVIEW_IMAGE_HEIGHT = exports.OVERVIEW_CONTENT_ELEMENT_ID = exports.DEFAULT_MINIBAG_WIDTH = exports.DEBOUNCE_DELAY = exports.CATALOGUE_PROVIDER_TYPE = exports.CATALOGUE_MAX_PAGE = exports.CATALOGUE_DEFAULT_PAGE = exports.ARIA_LABEL = void 0;
const CATALOGUE_DEFAULT_PAGE = exports.CATALOGUE_DEFAULT_PAGE = 1;
const CATALOGUE_MAX_PAGE = exports.CATALOGUE_MAX_PAGE = 32767;
const CATALOGUE_PROVIDER_TYPE = exports.CATALOGUE_PROVIDER_TYPE = 'IPaper';
const DEBOUNCE_DELAY = exports.DEBOUNCE_DELAY = 250;
const DEFAULT_MINIBAG_WIDTH = exports.DEFAULT_MINIBAG_WIDTH = '400px';
const OVERVIEW_CONTENT_ELEMENT_ID = exports.OVERVIEW_CONTENT_ELEMENT_ID = 'overview-content';
const OVERVIEW_IMAGE_HEIGHT = exports.OVERVIEW_IMAGE_HEIGHT = 230;
const PAGE_NUMBER_QUERY_KEY = exports.PAGE_NUMBER_QUERY_KEY = 'PageNumber';
const TOOLBAR_HEIGHT = exports.TOOLBAR_HEIGHT = '60px';
const ARIA_LABEL = exports.ARIA_LABEL = 'aria-label';
