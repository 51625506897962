"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
var _ApiProvider = require("./ApiProvider");
Object.keys(_ApiProvider).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _ApiProvider[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _ApiProvider[key];
        }
    });
});
