"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Root = void 0;
var _mui = require("@ori-ui/mui");
const Root = exports.Root = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(4),
        padding: theme.spacing(4, 3, 0),
        [theme.breakpoints.up('sm')]: {
            gap: theme.spacing(8),
            padding: theme.spacing(8, 3, 0)
        }
    };
});
