"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.swrConfig = void 0;
/* istanbul ignore file -- No need to test constant */ const swrConfig = exports.swrConfig = {
    errorRetryCount: 3,
    errorRetryInterval: 10000,
    focusThrottleInterval: 300000,
    shouldRetryOnError: true,
    suspense: false
};
