"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.generateImageUrls = void 0;
var _logger = require("../logger");
const generateImageUrls = function(catalogueUrl, totalPageCount) {
    let imageType = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : 'Thumb';
    const localLogger = _logger.mainLogger.createChild('generateImageUrls');
    try {
        const imageUrl = new URL('Image.ashx', catalogueUrl.replace(/\/?$/, '/'));
        return Array.from({
            length: totalPageCount
        }).map((_, index)=>{
            const pageNumber = index + 1;
            imageUrl.searchParams.set('PageNumber', pageNumber.toString());
            imageUrl.searchParams.set('ImageType', imageType);
            return {
                imageUrl: imageUrl.toString(),
                pageNumber
            };
        });
    } catch (error) {
        localLogger.warn('Failed to generate image URLs', {
            error: JSON.stringify(error)
        });
        return [];
    }
};
exports.generateImageUrls = generateImageUrls;
