"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.setCssVariables = void 0;
var _variables = require("../constants/variables");
const setCssVariables = ()=>{
    const bodyElement = document.body;
    const newTopNavHeight = getComputedStyle(bodyElement).getPropertyValue('--top-nav-height');
    const oldTopNav = document.querySelector('header.n-header');
    bodyElement.style.setProperty('--ecatalogue-toolbar-position-animation', 'height 0.3s ease-in-out');
    bodyElement.style.setProperty('--minibag-desktop-width', _variables.DEFAULT_MINIBAG_WIDTH);
    if (newTopNavHeight) {
        bodyElement.style.setProperty('--ecatalogue-toolbar-position', newTopNavHeight);
    } else if (oldTopNav) {
        bodyElement.style.setProperty('--ecatalogue-toolbar-position', "".concat(String(oldTopNav.offsetHeight), "px"));
    }
};
exports.setCssVariables = setCssVariables;
