"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.translationsNamespace = exports.defaultTranslations = void 0;
/* istanbul ignore file */ /**
 * DO NOT EDIT!
 * This file was autogenerated by npm script `localizations-codegen`.
 * Changes may cause incorrect behavior and will be lost when the file is regenerated.
 *
 * Run `npx localizations-codegen` to regenerate.
 */ const defaultTranslations = exports.defaultTranslations = {
    allPages: 'All pages',
    copy: 'Copy',
    download: 'Download',
    landingPageLatestCatalogueDescription: 'Read the latest eCatalogue (download as PDF or flip through online) and our guides to everything you need to know about NOVAGE+ skin care - our most advanced and powerful range, created by Swedish science.',
    landingPageLatestCatalogueSubtitle: 'eCatalogues and NOVAGE+ guides',
    landingPageLatestCatalogueTitle: 'The latest',
    landingPageOtherCataloguesTitle: 'Other',
    linkCopied: 'LinkCopied',
    navigatorInput: 'NavigatorInput',
    overview: 'Overview',
    shareTheCatalogue: 'Share the catalogue',
    singlePage: 'Single page',
    view: 'View'
};
const translationsNamespace = exports.translationsNamespace = 'products_catalogue-ipaper';
