"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.useProductClickHandler = void 0;
var _auth = require("@ori/auth");
var _configurationManager = require("@ori/configuration-manager");
var _logger = require("@ori/logger");
var _presentationHttp = require("@ori/presentation-http");
var _react = require("react");
var _getProduct = require("../api/getProduct");
var _onClickEvent = require("../utils/onClickEvent");
var _sendProductClickAnalytics = require("../utils/sendProductClickAnalytics");
const useProductClickHandler = ()=>{
    const localLogger = (0, _logger.useLogger)('useProductClickHandler');
    const { graphQlNetUrl } = (0, _configurationManager.useServiceUrls)([
        'globalApi',
        'graphQlNet'
    ]);
    const { token } = (0, _auth.useToken)();
    const { headers } = (0, _presentationHttp.useHeaders)();
    return (0, _react.useCallback)(async (param)=>{
        let { productId, pageIndex } = param;
        const { product } = await (0, _getProduct.getProduct)({
            graphQlUrl: graphQlNetUrl,
            headers,
            productCode: productId,
            token
        });
        (0, _onClickEvent.onClickEvent)({
            pageIndex,
            product
        });
        if (product) {
            (0, _sendProductClickAnalytics.sendProductClickAnalytics)(product, pageIndex);
        } else {
            localLogger.info("Product with code ".concat(productId, " not found."));
        }
    }, [
        graphQlNetUrl,
        headers,
        localLogger,
        token
    ]);
};
exports.useProductClickHandler = useProductClickHandler;
