"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
var _getPageNumberParamFromUrl = require("./getPageNumberParamFromUrl");
Object.keys(_getPageNumberParamFromUrl).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _getPageNumberParamFromUrl[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _getPageNumberParamFromUrl[key];
        }
    });
});
var _transformPageInput = require("./transformPageInput");
Object.keys(_transformPageInput).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _transformPageInput[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _transformPageInput[key];
        }
    });
});
var _updatePageNumberUrlParam = require("./updatePageNumberUrlParam");
Object.keys(_updatePageNumberUrlParam).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _updatePageNumberUrlParam[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _updatePageNumberUrlParam[key];
        }
    });
});
