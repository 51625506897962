"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.useIPaperInit = void 0;
var _logger = require("@ori/logger");
var _react = require("react");
var _utils = require("../utils");
var _getApiInstance = require("../utils/getApiInstance");
var _pageCounter = require("../utils/pageCounter");
var _useCatalogueUrlAndId = require("./useCatalogueUrlAndId");
var _usePageNumberContext = require("./usePageNumberContext");
var _useProductClickHandler = require("./useProductClickHandler");
/* istanbul ignore file */ const useIPaperInit = (iframeLoaded)=>{
    const localLogger = (0, _logger.useLogger)('useIPaperInit');
    const onProductAdded = (0, _useProductClickHandler.useProductClickHandler)();
    const { setCurrentPages, setCurrentPagesInitialized } = (0, _usePageNumberContext.usePageNumberContext)();
    const { catalogueUrl } = (0, _useCatalogueUrlAndId.useCatalogueUrlAndId)();
    const [initialized, setInitialized] = (0, _react.useState)(false);
    const [scriptLoaded, setScriptLoaded] = (0, _react.useState)(false);
    const setScriptLoadedTrue = (0, _react.useCallback)(()=>setScriptLoaded(true), []);
    const init = (0, _react.useCallback)(()=>{
        const iPaperApiInstance = (0, _getApiInstance.getApiInstance)();
        if (iPaperApiInstance && !initialized) {
            setInitialized(true);
            // Was in original implementation
            iPaperApiInstance.updateConfig({
                preventAction: {
                    basketClick: true
                }
            });
            // When bag icon is clicked, add to card, open PID or open shade picker
            void iPaperApiInstance.basket.onProductAdded(onProductAdded);
            void iPaperApiInstance.paging.getState().then((data)=>{
                setCurrentPages(data.currentSpread);
                setCurrentPagesInitialized(true);
            });
            iPaperApiInstance.paging.onChange((data)=>{
                setCurrentPages(data.currentSpread);
                // Fallback if ever paging getState stops working
                setCurrentPagesInitialized(true);
            });
            window.addEventListener('popstate', ()=>{
                iPaperApiInstance.paging.goToPage((0, _pageCounter.getPageNumberParamFromUrl)());
            });
            localLogger.debug('iPaperJsApi initialized');
        }
    }, [
        initialized,
        localLogger,
        onProductAdded,
        setCurrentPages,
        setCurrentPagesInitialized
    ]);
    (0, _react.useEffect)(()=>{
        let script = null;
        if (catalogueUrl) {
            script = (0, _utils.loadIPaperAPIScript)();
            script === null || script === void 0 ? void 0 : script.addEventListener('load', setScriptLoadedTrue);
        }
        return ()=>{
            script === null || script === void 0 ? void 0 : script.removeEventListener('load', setScriptLoadedTrue);
        };
    }, [
        catalogueUrl,
        setScriptLoadedTrue
    ]);
    (0, _react.useEffect)(()=>{
        // If script and iframe are loaded, we can initialize iPaperApiInstance.
        if (scriptLoaded && iframeLoaded) {
            init();
        // If rerender happens and iframe is again not loaded, we need to get a new initialization.
        } else if (!iframeLoaded) {
            setInitialized(false);
        }
    }, [
        iframeLoaded,
        init,
        scriptLoaded
    ]);
};
exports.useIPaperInit = useIPaperInit;
