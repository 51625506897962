"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
const _tagged_template_literal = require("@swc/helpers/_/_tagged_template_literal");
function _templateObject() {
    const data = _tagged_template_literal._([
        "\n  fragment Catalogue on CatalogueInfo {\n    catalogueType\n    title\n    imageUrl\n    validFrom\n    validTo\n    link {\n      relativeUrl\n      dynamicSharingUrl\n    }\n  }\n"
    ]);
    _templateObject = function() {
        return data;
    };
    return data;
}
function _templateObject1() {
    const data = _tagged_template_literal._([
        "\n  query CataloguesLandingPage {\n    application {\n      catalogueLandingPage {\n        digitalCatalogues {\n          ...Catalogue\n        }\n        otherCatalogues {\n          ...Catalogue\n        }\n      }\n    }\n  }\n  ",
        "\n"
    ]);
    _templateObject1 = function() {
        return data;
    };
    return data;
}
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.cataloguesLandingPageQuery = exports.catalogueFragment = void 0;
var _fetching = require("@ori/fetching");
// istanbul ignore file -- No need to test GraphQL query.
const catalogueFragment = exports.catalogueFragment = (0, _fetching.gql)(_templateObject());
const cataloguesLandingPageQuery = exports.cataloguesLandingPageQuery = (0, _fetching.gql)(_templateObject1(), catalogueFragment);
