"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.useCatalogueUrlAndId = void 0;
var _environment = require("@ori/environment");
var _i18n = require("@ori/i18n");
var _logger = require("@ori/logger");
var _presentationHttp = require("@ori/presentation-http");
var _presentationUtils = require("@ori/presentation-utils");
var _staticApiClientReact = require("@ori/static-api-client-react");
var _react = require("react");
var _utils = require("../utils");
var _useConfiguration = require("./useConfiguration");
// istanbul ignore next -- No need to test usage of logger.
const onError = (localLogger)=>(error)=>{
        localLogger.error(new Error('Failed fetch of catalogue overview', {
            cause: error
        }));
    };
const useCatalogueUrlAndId = ()=>{
    const { language } = (0, _i18n.useTranslationsWithNamespaces)();
    const { tenant } = (0, _environment.useEnvironment)();
    const localLogger = (0, _logger.useLogger)('useCatalogueUrlAndId');
    const { catalogueCode, isFlyer } = (0, _useConfiguration.useConfiguration)();
    const { customerTypeId, loading, visitorSegment } = (0, _presentationHttp.useUserSegmentationData)();
    const { data, isValidating } = (0, _staticApiClientReact.useGetTenantApplicationsCatalogueOverview)(tenant, {
        customerTypeId: customerTypeId !== null && customerTypeId !== void 0 ? customerTypeId : undefined,
        lang: language,
        visitorSegment: visitorSegment !== null && visitorSegment !== void 0 ? visitorSegment : undefined
    }, {
        swr: {
            enabled: !loading,
            revalidateIfStale: false,
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            onError: onError(localLogger)
        }
    });
    const { catalogueId = null, catalogueUrl = null } = (0, _react.useMemo)(()=>(0, _utils.getCatalogueUrlAndId)({
            catalogueCode,
            data,
            isFlyer
        }), [
        catalogueCode,
        data,
        isFlyer
    ]);
    return (0, _react.useMemo)(()=>({
            catalogueId,
            catalogueUrl,
            isLoading: isValidating || (0, _presentationUtils.isSSR)()
        }), [
        catalogueId,
        catalogueUrl,
        isValidating
    ]);
};
exports.useCatalogueUrlAndId = useCatalogueUrlAndId;
