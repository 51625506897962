"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
var _useCatalogueUrlAndId = require("./useCatalogueUrlAndId");
Object.keys(_useCatalogueUrlAndId).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _useCatalogueUrlAndId[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _useCatalogueUrlAndId[key];
        }
    });
});
var _useConfiguration = require("./useConfiguration");
Object.keys(_useConfiguration).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _useConfiguration[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _useConfiguration[key];
        }
    });
});
var _useGetCatalogueStructure = require("./useGetCatalogueStructure");
Object.keys(_useGetCatalogueStructure).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _useGetCatalogueStructure[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _useGetCatalogueStructure[key];
        }
    });
});
var _useIPaperInit = require("./useIPaperInit");
Object.keys(_useIPaperInit).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _useIPaperInit[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _useIPaperInit[key];
        }
    });
});
var _useOverviewContext = require("./useOverviewContext");
Object.keys(_useOverviewContext).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _useOverviewContext[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _useOverviewContext[key];
        }
    });
});
var _usePageNumberContext = require("./usePageNumberContext");
Object.keys(_usePageNumberContext).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _usePageNumberContext[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _usePageNumberContext[key];
        }
    });
});
var _useProductClickHandler = require("./useProductClickHandler");
Object.keys(_useProductClickHandler).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _useProductClickHandler[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _useProductClickHandler[key];
        }
    });
});
var _useToggle = require("./useToggle");
Object.keys(_useToggle).forEach(function(key) {
    if (key === "default" || key === "__esModule") return;
    if (key in exports && exports[key] === _useToggle[key]) return;
    Object.defineProperty(exports, key, {
        enumerable: true,
        get: function() {
            return _useToggle[key];
        }
    });
});
