"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.getPageNumber = exports.PAGE_NUMBER_PARAM_NAME = void 0;
var _presentationUtils = require("@ori/presentation-utils");
var _tsUtils = require("@ori/ts-utils");
const PAGE_NUMBER_PARAM_NAME = exports.PAGE_NUMBER_PARAM_NAME = 'PageNumber';
const getPageNumber = (request)=>{
    const pageNumber = Number((0, _tsUtils.isNonNullable)(request) ? (0, _presentationUtils.getServerQueryParam)(request, PAGE_NUMBER_PARAM_NAME) : (0, _presentationUtils.getClientQueryParam)(PAGE_NUMBER_PARAM_NAME));
    return Number.isInteger(pageNumber) ? pageNumber : null;
};
exports.getPageNumber = getPageNumber;
