"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Root = void 0;
var _mui = require("@ori-ui/mui");
var _presentationComponents = require("@ori/presentation-components");
const Root = exports.Root = (0, _mui.styled)('div')((param)=>{
    let { theme } = param;
    return {
        display: 'grid',
        gap: theme.spacing(4, 2),
        gridTemplateColumns: "repeat(2, minmax(0, ".concat(_presentationComponents.CATEGORY_BOX_MOBILE_MEDIUM_WIDTH, "px))"),
        [theme.breakpoints.up('sm')]: {
            gap: theme.spacing(6),
            gridTemplateColumns: "repeat(3, minmax(0, ".concat(_presentationComponents.CATEGORY_BOX_DESKTOP_MEDIUM_WIDTH, "px))")
        },
        [theme.breakpoints.up(750)]: {
            gridTemplateColumns: "repeat(4, minmax(0, ".concat(_presentationComponents.CATEGORY_BOX_DESKTOP_MEDIUM_WIDTH, "px))")
        },
        [theme.breakpoints.up('md')]: {
            gap: theme.spacing(8),
            gridTemplateColumns: "repeat(5, minmax(0, ".concat(_presentationComponents.CATEGORY_BOX_DESKTOP_MEDIUM_WIDTH, "px))")
        }
    };
});
