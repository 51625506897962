"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.getCatalogueUrlAndId = void 0;
var _getCatalogueMetadata = require("./getCatalogueMetadata");
var _getCatalogueMetadataForProviderType = require("./getCatalogueMetadataForProviderType");
const getCatalogueUrlAndId = (param)=>{
    let { catalogueCode, data, isFlyer } = param;
    var _catalogueMetadataForProviderType_url;
    const catalogueMetadata = (0, _getCatalogueMetadata.getCatalogueMetadata)({
        catalogueCode,
        isFlyer,
        data
    });
    const catalogueMetadataForProviderType = (0, _getCatalogueMetadataForProviderType.getCatalogueMetadataForProviderType)(catalogueMetadata);
    const catalogueUrl = catalogueMetadataForProviderType === null || catalogueMetadataForProviderType === void 0 ? void 0 : (_catalogueMetadataForProviderType_url = catalogueMetadataForProviderType.url) === null || _catalogueMetadataForProviderType_url === void 0 ? void 0 : _catalogueMetadataForProviderType_url.replace(/\/?$/, '');
    const catalogueId = catalogueMetadataForProviderType === null || catalogueMetadataForProviderType === void 0 ? void 0 : catalogueMetadataForProviderType.id;
    return {
        catalogueUrl,
        catalogueId
    };
};
exports.getCatalogueUrlAndId = getCatalogueUrlAndId;
