"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.updatePageNumberUrlParam = void 0;
var _constants = require("../../constants");
const updatePageNumberUrlParam = (pageNumber)=>{
    const urlParams = new URLSearchParams(window.location.search);
    const pageNumberValue = pageNumber.toString();
    if (urlParams.get(_constants.PAGE_NUMBER_QUERY_KEY) === pageNumberValue) {
        // We will proceed with updating the history only in case we are moving
        // to the different page than it is already present in current history state
        return;
    }
    urlParams.set(_constants.PAGE_NUMBER_QUERY_KEY, pageNumberValue);
    const newRelativePathQuery = "".concat(window.location.pathname, "?").concat(urlParams.toString());
    window.history.pushState(null, document.title, newRelativePathQuery);
};
exports.updatePageNumberUrlParam = updatePageNumberUrlParam;
