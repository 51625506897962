"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Root = void 0;
var _mui = require("@ori-ui/mui");
const Root = exports.Root = (0, _mui.styled)('ul')((param)=>{
    let { theme } = param;
    return {
        background: theme.palette.common.white,
        boxSizing: 'border-box',
        display: 'grid',
        gridAutoRows: 'min-content',
        gridTemplateColumns: 'repeat(auto-fill, minmax(175px, 1fr))',
        justifyContent: 'center',
        listStyle: 'none',
        maxHeight: '100%',
        overflow: 'auto',
        gap: theme.spacing(6, 0),
        padding: theme.spacing(2, 1),
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(4),
            gap: theme.spacing(10, 10)
        }
    };
});
