"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.getCorrectCatalogueIdFormat = void 0;
const getCorrectCatalogueIdFormat = (id)=>{
    if (!id) {
        return '';
    }
    const editedId = id.startsWith('/') ? id : "/".concat(id);
    return encodeURIComponent(editedId.endsWith('/') ? editedId : "".concat(editedId, "/"));
};
exports.getCorrectCatalogueIdFormat = getCorrectCatalogueIdFormat;
