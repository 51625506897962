"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.mainLogger = void 0;
var _logger = require("@ori/logger");
var _constantsGenerated = require("./constants-generated");
const mainLogger = exports.mainLogger = new _logger.Logger(_constantsGenerated.PACKAGE_NAME, {
    teamName: _constantsGenerated.TEAM_NAME
});
