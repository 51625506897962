"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.getCatalogueMetadata = void 0;
const getCatalogueMetadata = (param)=>{
    let { catalogueCode, isFlyer, data } = param;
    var _data_catalogues_find;
    const metadataCollectionIndex = isFlyer ? 1 : 0;
    return data === null || data === void 0 ? void 0 : (_data_catalogues_find = data.catalogues.find((catalogueType)=>{
        var _catalogueType_metadataCollection_metadataCollectionIndex_id, _catalogueType_metadataCollection_metadataCollectionIndex;
        return (_catalogueType_metadataCollection_metadataCollectionIndex = catalogueType.metadataCollection[metadataCollectionIndex]) === null || _catalogueType_metadataCollection_metadataCollectionIndex === void 0 ? void 0 : (_catalogueType_metadataCollection_metadataCollectionIndex_id = _catalogueType_metadataCollection_metadataCollectionIndex.id) === null || _catalogueType_metadataCollection_metadataCollectionIndex_id === void 0 ? void 0 : _catalogueType_metadataCollection_metadataCollectionIndex_id.includes(catalogueCode);
    })) === null || _data_catalogues_find === void 0 ? void 0 : _data_catalogues_find.metadataCollection;
};
exports.getCatalogueMetadata = getCatalogueMetadata;
